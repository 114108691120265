const vendors = [
  {
    name: "Axel Springer Teaser Ad GmbH",
    value: "axel springer teaser ad gmbh",
    iabVendorId: 647,
    mutation: "ads/setAllowAdup"
  },
  {
    name: "Google Ads",
    value: "google advertising products",
    iabVendorId: 755,
    mutation: "ads/setAllowGoogleAds"
  },
  {
    name: "Google Analytics",
    value: "google analytics",
    mutation: "ads/setAllowGoogleAnalytics"
  },
  {
    name: "Google Optimize",
    value: "google optimize",
    mutation: "ads/setAllowGoogleOptimize"
  },
  {
    name: "Criteo SA",
    value: "criteo sa",
    iabVendorId: 91,
    mutation: "ads/setAllowCriteo"
  },
  {
    name: "RTB House S.A.",
    value: "rtb house",
    iabVendorId: 16,
    mutation: "ads/setAllowRTBHouse"
  },
  {
    name: "Hotjar",
    value: "hotjar",
    mutation: "ads/setAllowHotjar"
  },
  {
    name: "Facebook, Inc.",
    value: "facebook",
    mutation: "ads/setAllowFacebook"
  },
  {
    name: "TikTok",
    value: "tiktok",
    mutation: "ads/setAllowTikTok"
  },
  {
    name: "INFOnline GmbH",
    value: "infonline gmbh",
    iabVendorId: 730,
    mutation: "ads/setAllowInfonline"
  },
  {
    name: "LinkedIn Ads",
    value: "linkedin ireland unlimited company",
    iabVendorId: 804,
    mutation: "ads/setAllowLinkedIn"
  },
  {
    name: "Microsoft Advertising",
    value: "microsoft advertising",
    mutation: "ads/setAllowMicrosoft"
  },
  {
    name: "Shopping Minds bv",
    value: "shopping minds",
    mutation: "ads/setAllowShoppingMinds"
  },
  {
    name: "Selligent CRM System",
    value: "selligent crm",
    mutation: "ads/setAllowSelligent"
  },
  {
    name: "Genesis Media",
    value: "genesis media",
    mutation: "ads/setAllowGenesis"
  },
  {
    name: "Spotify AB",
    value: "spotify ab",
    mutation: "ads/setAllowSpotify"
  },
  {
    name: "AWIN AG",
    value: "awin ag",
    iabVendorId: 907,
    mutation: "ads/setAllowAwin"
  }
]

/* global SDG */
/* eslint-disable no-console */
export default {
  data() {
    return {
      cookieStore: {
        stroeerLoaded: false,
        hasSentConsent: false,
        tracking: {
          init: ""
        }
      }
    }
  },
  methods: {
    async consentCheck() {
      let consentVendorString = ""
      // console.log('=== Stroeer Consent Check ===');

      for (let vendor of vendors) {
        // console.log('=== Check Vendor', vendor.name);
        // Check IAB Vendors
        if (vendor.iabVendorId) {
          await new Promise((resolve) => {
            SDG.Publisher.getIABVendorConsent(vendor.iabVendorId, (e) => {
              // console.log(`📊 ${vendor.name} response ${vendor.iabVendorId}`, e);
              if (e === true) {
                this.cookieStore[vendor.mutation] = true
                consentVendorString += `${vendor.value}, `
              }

              resolve();
            })
          });
        } else {
          // Check NON-IAB Vendors
          const allowVendor = SDG.Publisher.getCustomVendorConsent(vendor.name)
          // console.log(
          //   `📊 ${vendor.name} response ${vendor.value}`,
          //   allowVendor,
          // );
          this.cookieStore[vendor.mutation] = true
          if (allowVendor) {
            consentVendorString += `${vendor.value}, `
          }
        }
      }

      if (!this.cookieStore.hasSentConsent) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "guru_consent",
          guru_consent_granted: consentVendorString
        })
        this.cookieStore.hasSentConsent = true
      }

      // TCF INIT
      this.initTCF()
    },
    initTCF() {
      // TCF API Checks
      if (!window.__tcfapi) return
      window.__tcfapi(
        "addEventListener",
        2,
        function (consentData, callbackSuccessfull) {
          const status = consentData.eventStatus
          const purposeConsents = consentData.purpose.consents

          if (
            callbackSuccessfull &&
            (status === "tcloaded" || status === "useractioncomplete")
          ) {
            if (
              purposeConsents["1"] === true &&
              purposeConsents["2"] === true &&
              purposeConsents["4"] === true &&
              purposeConsents["7"] === true
            ) {
              // publisherScript.loadPublisherVendors()
              // console.log(
              //   'Purpose 1, 2, 4 and 7 have consent : = : Load Vendors!',
              // );
              // console.log('=== === === ');
              // console.log('Status ', status);
              // console.log('=== === === ');
              // console.log('Purposes ', purposeConsents);

              window.__tcfapi(
                "removeEventListener",
                2,
                function () {},
                consentData.listenerId
              )
            }
          }
        }
      )
    }
  }
}
