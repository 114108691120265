<template>
  <div
    id="hg-notification"
    :class="[
      isProfileComplete && 'hg-notification--success',
      closed ? 'closed' : 'active'
    ]"
    v-if="!$apollo.loading"
  >
    <div v-if="me && !isProfileComplete" class="message">
      <div class="content">
        <span class="title">Hey, {{ me.profile.firstName }}!</span>
        <p>
          {{ message }}
        </p>
      </div>
      <div class="actions">
        <a :href="settingsUrl" class="button">Profil vervollständigen</a>
        <button @click="closed = true">jetzt nicht</button>
      </div>
    </div>
    <div v-else-if="me">
      <span class="title">Hey, {{ me.profile.firstName }}!</span>
      <div>Du hast ein vollständiges Profil!</div>
    </div>
    <div v-else>
      Du bist nicht angemeldet
    </div>
  </div>
</template>

<script>
import meQuery from "../../apollo/queries/auth/me.gql";
export default {
  props: {
    settingsUrl: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    closed: true,
    message: null,
    isProfileComplete: false
  }),
  methods: {
    close() {
      console.log(this);
    }
  },
  apollo: {
    me: {
      query: meQuery,
      result({ data }) {
        if (!data) return;
        if (
          data.me.profile.sex === null ||
          data.me.profile.sex === "" ||
          data.me.profile.location.formatted === null ||
          data.me.profile.location.formatted === ""
        ) {
          this.isProfileComplete = false;
          this.closed = false;
          this.message = "Dein Profil ist noch nicht vollständig!";
        } else {
          this.closed = false;
          this.message = "Dein Profil ist vollständig!";
          this.isProfileComplete = true;
        }
      },
      error(e) {
        console.log("JO ERROR", e);
      }
    }
  }
};
</script>

<style scoped>
#hg-notification {
  font-family: "Asap", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: 16px;

  background-color: #fff7a0;
  padding: 1rem;
  border-radius: 0.5rem;
}

#hg-notification.closed {
  display: none;
}

.title {
  font-weight: 900;
}

.message {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1180px;
  margin: 0 auto;
  padding: 0 2rem;
}

.actions {
  display: flex;
  flex-direction: column;
}

.button {
  background-color: #00d5e0;
  text-decoration: none;
  color: #24404f;
  padding: 0.75rem 1.5rem;
  border-radius: 0.375rem;
  font-weight: 900;
  white-space: nowrap;
}

.actions button {
  appearance: none;
  border: none;
  background-color: transparent;
  /* text-decoration: underline; */
  margin-top: 0.5rem;
  opacity: 0.65;
}
</style>
