<template>
  <div class="holidayguru">
    <HgFooterNew
      class="margin"
      id="hg-footer-new"
      :lang="lang"
      :phoneNr="phoneNr"
      :activate-cookie-consent="!noCookieConsent"
      :agb-link="agbLink"
      :maxWidth="maxWidth"
      @onCookieButtonClick="cookieButtonClick"
      @onNewsletterButtonClick="newsletterButtonClick"
    />

    <StroeerLoader
      v-if="!noCookieConsent && !noStroeerLoader && !cookieFunction"
    />
  </div>
</template>

<script>
import { HgFooterNew, HgFooterContactLocales } from "@uniqgmbh/holidayguru-components"
import StroeerLoader from "../components/StroeerLoader.vue"

export default {
  components: {
    HgFooterNew,
    StroeerLoader
  },
  props: {
    noCookieConsent: {
      type: Boolean,
      default: false
    },
    noStroeerLoader: {
      type: Boolean,
      default: false
    },
    locale: {
      type: String,
      default: null
    },
    cookieFunction: {
      type: Function,
      default: null
    },
    phoneNr: {
      type: String,
      default: ''
    },
    supportMessage: {
      type: String,
      default: ''
    },
    agbLink: {
      type: String,
      default: null
    },
    maxWidth: {
      type: String,
      default: ""
    }
  },
  created() {
    if (this.locale) {
      this.lang = this.locale
    } else if (window.location.host.includes(".at")) {
      this.lang = "at"
    } else if (window.location.host.includes(".ch")) {
      this.lang = "ch"
    } else if (window.location.host.includes(".es")) {
      this.lang = "es"
    } else if (window.location.host.includes(".nl")) {
      this.lang = "nl"
    } else {
      this.lang = "de"
    }

    if (this.supportMessage != null && this.supportMessage.length > 0) {
      HgFooterContactLocales.de.message = this.supportMessage
      HgFooterContactLocales.at.message = this.supportMessage
      HgFooterContactLocales.ch.message = this.supportMessage
    }
  },
  methods: {
    newsletterButtonClick() {
      if (this.lang == "at") {
        window.location.href = "https://www.urlaubsguru.at/newsletter/"
      } else if (this.lang == "ch") {
        window.location.href = "https://www.holidayguru.ch/newsletter/"
      } else if (this.lang == "es") {
        window.location.href = "https://www.holidayguru.es/newsletter/"
      } else if (this.lang == "nl") {
        window.location.href = "https://www.holidayguru.nl/newsletter/"
      } else {
        window.location.href = "https://www.urlaubsguru.de/newsletter/"
      }
    },
    cookieButtonClick() {
      if (this.cookieFunction) {
        this.cookieFunction()
      } else {
        window._sp_.gdpr.loadPrivacyManagerModal(
          this.$i18n.messages[this.lang].privacyManagerModalId
        )
      }
    }
  }
}
</script>

<style lang="postcss">
.hg-button {
  white-space: nowrap;
}
.tw-h-12 {
  height: 3rem;
}

.margin {
  margin-top: 2.8125rem;
}
</style>
